define("ember-simple-auth/initializers/setup-session-restoration", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setupSessionRestoration;

  function setupSessionRestoration(registry) {
    const ApplicationRoute = registry.resolveRegistration ? registry.resolveRegistration('route:application') : registry.resolve('route:application');
    ApplicationRoute.reopen({
      init() {
        this._super(...arguments);

        const originalBeforeModel = this.beforeModel;
        console.error("ApplicationRoute.reopen originalBeforeModel " + originalBeforeModel);

        this.beforeModel = function () {
          console.error("beforeModel " + this.__usesApplicationRouteMixn__);
          console.dir(this);

          if (!this.__usesApplicationRouteMixn__) {
            const sessionService = (0, _application.getOwner)(this).lookup('service:session');

            sessionService._setupHandlers();
          }

          const session = (0, _application.getOwner)(this).lookup('session:main');
          return session.restore().then(() => originalBeforeModel.apply(this, arguments), () => originalBeforeModel.apply(this, arguments));
        };
      }

    });
  }
});